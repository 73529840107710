import { fetchPost, fetchGet, fetchPostPic } from "../index";
import axios from "axios";
import { TypeClassItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
// 查询table数据接口
export const getQueryList = (params = {}): Promise<any> => {
  return fetchPost(
    "/claimapi/salesIncentiveClaim/claimApplicationList",
    params
  );
};
// 获取ModelYear列表数据
export const getModelYear = (): Promise<any> => {
  return fetchGet("/vapi/noAuth/get_property_lov?property=modelYear");
};
/*// 获取checking round列表数据
export const getCheckingRoundList = (): Promise<any> => {
    return fetchGet('/claimapi/checking/plan/checking/round/dropList');
};*/
// 获取bu下拉框数据
export const getBuList = (): Promise<any> => {
  // return fetchGet('/claimapi/doc/dic/getBuList')
  return fetchGet("/mdapi/bu");
};
//生成region列表
export const getRegionLst = (params = { bu: "" }): Promise<any> => {
  return fetchPost(`/claimapi/common/regionQuery?bu=${params.bu}`, params);
};
//生成region,subregion列表
export const getRegion = (params: any): Promise<any> => {
  return fetchPost(
    `/claimapi/common/regionRelationQuery?buId=${
      params.buId ? params.buId : ""
    }&regionId=${params.regionId ? params.regionId : ""}`,
    params
  );
};
export const getRegionList = (buIdList: number[], regionId?: number): Promise<any> => {
  return fetchPost('/claimapi/common/getRegionRelationByBuIdListAndRegionId', {}, {
    params: {
      buIdList,
      regionId
    }
  });
}
export const getRegionDealerGroup = (params: any): Promise<any> => {
  return fetchPost(`/rvapi/basic/webApi/report/regions`, params);
};
//通过bu获取subregion列表
export const getBuSubregion = (params: any): Promise<any> => {
  return fetchPost(
    `/claimapi/common/retrieveSubRegionList?buId=${
      params.buId ? params.buId : ""
    }`,
    params
  );
};
// 查询Dealer table数据接口
export const getDealerList = (params: any): Promise<any> => {
  return fetchPost(
    `/claimapi/dealer/communication/analysis/claim/checking/report/dealer/based/page`,
    params
  );
};
// 查询Program table数据接口
export const getProgramList = (params: any): Promise<any> => {
  return fetchPost(
    `/claimapi/dealer/communication/analysis/claim/checking/report/program/based/page`,
    params
  );
};
export const getComDealerList = (params: any): Promise<any> => {
  return fetchPost(
    // `/claimapi/dealer/communication/analysis/dealer/based/page`,
    `/rpapi/dealer/communication/analysis/dealer/based/page`,
    params
  );
};
// 查询Program table数据接口
export const getComProgramList = (params: any): Promise<any> => {
  return fetchPost(
    // `/claimapi/dealer/communication/analysis/program/based/page`,
    `/rpapi/dealer/communication/analysis/program/based/page`,
    params
  );
};
export const getDemoDealerList = (params: any): Promise<any> => {
  return fetchPost(`/rpapi/demo/car/report/dealer/based/page`, params);
};
// 查询Program table数据接口
export const getDemoProgramList = (params: any): Promise<any> => {
  return fetchPost(`/rpapi/demo/car/report/program/based/page`, params);
};
export const getCesarAnalyst = (params: any): Promise<any> => {
  return fetchGet("/rvapi/report/cesar/query/avgAnaly", { params });
};
export const getCesarBased = (params: any): Promise<any> => {
  return fetchGet("/rvapi/report/cesar/query/weekBase", { params });
};
// 查询payout breakdown
export const getPayoutBreakdown = (params: any): Promise<any> => {
  return fetchPost("/claimapi/payoutBreakdown/search", params);
};
export const getPayoutBreakdown2 = (params: any): Promise<any> => {
  return fetchPost(
    "/claimapi/payoutBreakdown/searchClaimCheckingOverview",
    params
  );
};
//根据bu，查checkinground, 11661
export const getCheckingRoundList = (params: any): Promise<any> => {
  return fetchGet("/claimapi/checking/program/checkingRoundList", { params });
};
//获取TypeClass
export const getTypeClass = (): Promise<TypeClassItem[]> => {
  return fetchGet("/vapi/noAuth/get_property_lov?property=typeClass");
};
//获取DealerGroup
export const getDealerGroup = (): Promise<any> => {
  return fetchGet("/dapi/noAuth/dealer/dealerGroupList");
};

// 获取Checking PL&AC列表Checking Round View维度 size=-1获取所有 正常写获取分页
export const planActualRoundPage = (params: any): Promise<any> => {
  return fetchPost(
    "/claimapi/checking/plan/actual/checking/round/page",
    params
  );
};

// 导出数据tabName=
export const planActualExport = (params: any): Promise<any> => {
  return fetchPost(
    "/claimapi/checking/plan/actual/claim/checking/report/export",
    params
  );
};

// 获取Checking PL&AC列表Checking Round by Program Cagegory
export const planActualCategory = (params: any): Promise<any> => {
  return fetchPost(
    "/claimapi/checking/plan/actual/program/cagegory/page",
    params
  );
};

// 1-6 Payment Tracking Report查询表格
export const getPaymentTrackingReportTable = (params = {}): Promise<any> => {
  return fetchPost(
    // '/ptapi/paymentTracking/report/paymentTrackingList',
    "/rpapi/paymentTracking/report/paymentTrackingList",
    params
  );
};
