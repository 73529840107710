import {
  fetchGet,
  fetchDelete,
  fetchPost,
  fetchGetMess,
  fetchPostPic,
} from "../index";
import downloadFile from "@/utils/claim/downloadFile";
import axios from "axios";
import { downloadFromStream } from "@/utils/payment/downloadFile";

//生成SICCO Checing Deatail的列表
export const getSICCOChecingDetailList = (params = {}): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPost("/claimapi/checking/page", params);
};

//生成SIPO Checing Deatail的列表
export const getSIPOChecingDetailList = (params = {}): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPost("/claimapi/communication/page", params);
};

//获取checkingSourceData列表
export const getSourceData = (
  params = { programCode: "0", offerTypeId: "0" }
): Promise<any> => {
  return fetchGet(
    `/claimapi/source/data/list/${params.programCode}/${params.offerTypeId}`,
    { params }
  );
};

//删除Source data
export const deleteSourceDataApi = (
  params = { sourceDataId: "0" }
): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchDelete(`/claimapi/source/data/delete/${params.sourceDataId}`);
};

//下载shorce data
export const downloadSourceDataApi = (
  params = { sourceDataId: "0" }
): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchGet(`/claimapi/source/data/downloadUrl/${params.sourceDataId}`);
};

// //下载shorce data
// export const downloadSourceDataApi= (params = {sourceDataId:'0'}): void => {
//     // return fetchGet('/claimapi/dealer/doc/save', {params})
//     console.log(`/claimapi/source/data/downloadUrl/${params.sourceDataId}`)
//     const option = {
//         url: `/claimapi/source/data/downloadUrl/${params.sourceDataId}`,
//         method: 'get'
//     }
//     downloadFile(option, 'application/vnd-excel; char-set=UTF-8')
// }

export const downloadDetail = (params = {}): void => {
  const option = {
    url: `/claimapi/checking/plan/export/detail`,
    method: "get",
    params,
  };
  downloadFile(option, "application/vnd-excel; char-set=UTF-8");
};

//上传Source Data
export const uploadSourceData = <T>(formData: FormData): Promise<T> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      data: formData,
      url: "/claimapi/source/data/upload",
      //  params: params,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//添加Source Data
export const addSourceData = (data: any): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPostPic("/claimapi/source/data/add", data);
};

//Pending按钮事件
export const checkingPending = (params = {}): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPostPic("/claimapi/checking/pending", params);
};

//Reject按钮事件
export const checkingReject = (params = {}): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPostPic("/claimapi/checking/reject", params);
};

//Submit按钮事件
export const checkingSubmit = (params = {}): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPostPic("/claimapi/checking/batchSubmit", params);
};

//Approve事件
export const checkingApprove = (params = {}): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPostPic("/claimapi/checking/approve", params);
};

//获取Detail Documents
export const getDetailDocumentsApi = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/checking/record/documents", { params });
};

//获取Detail Operation Record
export const getDetailOperationRecordApi = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/checking/record/list", { params });
};
//获取Detail Retail
export const getDetailRetailApi = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/basic/webApi/claim/vechileRetail", { params });
};

//显示RV的图片

export const getRVFile = (
  params = { id: "", type: "", fileName: "" }
): Promise<any> => {
  console.log(params);
  console.log(`docapi/download/${params.id}/${params.type}/${params.fileName}`);
  return fetchGet(
    `docapi/download/${params.id}/${params.type}/${params.fileName}`
  );
};

//显示RV的pdf
export const pdfViewApi = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/basic/webApi/claim/pdfView", { params });
};

//数据字典，查下拉框信息
export const getFindByGroupCode = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/claimDictionary/findByGroupCode", { params });
};

//导出功能,SPCCO
export const exportPageApi = (params = {}): void => {
  const option = {
    url: `/claimapi/checking/export`,
    method: "post",
    params,
  };
  downloadFile(option, "application/vnd-excel; char-set=UTF-8");
};
// 导出 Demo car Mileage
export const exportDemoCarMileage = async (params = {}): Promise<void> => {
  const res = await axios.post("/claimapi/demo/car/exportMiles", null, {
    params,
    responseType: "blob",
  });
  downloadFromStream(res);
};
//导出SIPO
export const exportPageApiSIPO = (params = {}): void => {
  const option = {
    url: `/claimapi/dealer/communication/export`,
    method: "post",
    params,
  };
  downloadFile(option, "application/vnd-excel; char-set=UTF-8");
};

//上传 Import Checking Result
export const upImportCheckingResult = <T>(
  params: any,
  format: FormData
): Promise<T> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  console.log(params.file);
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      params: params,
      data: format,
      url: `/claimapi/checking/upload`,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ImportCheckingResult = <T>(
  params: any,
  format: FormData
): Promise<T> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      params: params,
      data: format,
      url: `/claimapi/checking/import`,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Reject Sipo
export const rejectSIPO = (data: any): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPostPic("/claimapi/communication/rejectToSICCO", data);
};
//Reject Sipo Confirm
export const rejectSIPOConfirm = (data: any): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPostPic("/claimapi/communication/rejectToSICCO/confirm", data);
};

//confirm Sipo
export const confirmSIPO = (data: any): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPostPic("/claimapi/communication/SIPOConfirm", data);
};

//confirm Sipo Confirm
export const confirmSIPOConfirm = (data: any): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPostPic("/claimapi/communication/SIPOConfirm/confirm", data);
};

//获取getcheckingState
export const getCheckingState = (type = "checkingStatus"): Promise<any> => {
  return fetchPost(`/claimapi/common/${type}`);
};

//获取4977统计数量
export const getGroupNum = (params = {}): Promise<any> => {
  return fetchGet(
    "/claimapi/checking/findGroupByProgDesignIdAndOfferTypeIdCount",
    { params }
  );
};

//获取4977 ModelYear
export const getModelYearApi = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/common/modelYears", { params });
};

//4977根据program信息获取bu，用bu获取region
export const getProgramInfoApi = (param: any): Promise<any> => {
  return fetchPost(
    `/claimapi/common/checking/getProgramInfo?programId=${param.programId}&offerTypeId=${param.offerTypeId}`
  );
};

//获取4954，4977的Region
export const getRegionApi = (param: any): Promise<any> => {
  return fetchPost(`/claimapi/common/regionQuery?bu=${param.bu}`);
};

//4977 set Dealer Communication 之前的统计数据
export const setDealerCommunicate = (data: any): Promise<any> => {
  return fetchPostPic("/claimapi/communication/setDealerCommunicate", data);
};

//4977 set Dealer Communication
export const checkSignCommunicationConfirm = (data: any): Promise<any> => {
  return fetchPostPic(
    "/claimapi/checking/sipo/main/check/sign/set/dealer/communication/confirm",
    data
  );
};

//4977 set Dealer Communication 新接口
export const checkSignCommunicationConfirmApi = (data: any): Promise<any> => {
  return fetchPostPic(
    "/claimapi/communication/setDealerCommunicate/confirm",
    data
  );
};

//4954中的Approve,Reject,Pending中的tag的下拉选项模糊查询的返回值
export const getTagApi = (params: any): Promise<any> => {
  return fetchPost(
    `/claimapi/common/checking/firstComments?commentsSearch=${params.commentsSearch}`
  );
};
//4954中的Approve,Reject,Pending中的Descirption的下拉选项模糊查询的返回值
export const getDescirptionApi = (params: any): Promise<any> => {
  return fetchPost(
    `/claimapi/common/checking/secondComments?commentsSearch=${params.commentsSearch}&tag=${params.tag}`
  );
};
//4954中的Approve,Reject,Pending中的Internal Comment预设值
export const getInternalCommentsApi = (params: any): Promise<any> => {
  return fetchPost(
    `/claimapi/checking/getInternalComments?programId=${params.programId}&dealerCode=${params.dealerCode}&vinNo=${params.vinNo}&id=${params.id}`
  );
};

//4954查询下载文件大小和数量
export const getDocumentNotice = (data: any): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPostPic(
    "/claimapi/checking/Supporting/doc/download/documents/notice",
    data
  );
};

//4954文件下载判断
export const downloadDocuments = (data: any): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPostPic(
    "/claimapi/checking/Supporting/doc/download/documents",
    data
  );
};

//新版，4954，4977 Document的列表
export const getDocumentTable = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/appeal/file/claim/detail/doc", { params });
};

//新版，预览4954，4977 Document文件接口
export const documentReview = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/appeal/file/support/review", { params });
};

//获取checkingPlan Round,当4975跳转到4977时，没有带相对应参数时
export const findRoundAndBatch = (params = {}): Promise<any> => {
  return fetchGet("/claimapi/checking/findRoundAndBatch", { params });
};

export const getClaimType = (): Promise<{ key: string; name: string }[]> => {
  return fetchGet("/claimapi/common/claimType");
};

export const getStatistics = (params = {}): Promise<any> => {
  return fetchPost("/claimapi/checking/all/list", params);
};
