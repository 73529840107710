import { fetchGet, fetchDelete, fetchPost, fetchPostPic } from '../../index';
import downloadFile from '@/utils/claim/downloadFile';
import axios from 'axios';
// 查询2275上方tabs页数据
// export const apitest = (): Promise<any> => {
// 	return fetchGet('claimapi/parameter/setup/getParameterPeriod?progDesignId=1')
// }

//过去激励类型和项目类型
export const getOfferType = (): Promise<any> => {
    return fetchGet('/claimapi/common/getIncentiveOfferType');
};
//获取项目状态
export const getStatus = (): Promise<any> => {
    return fetchGet('/claimapi/common/programStatus');
};

//获取概览总数
export const getSubmissionOverview = (
    params = { dealerCode: '1' }
): Promise<any> => {
    return fetchGet('/claimapi/dealer/submissionOverview', { params });
};

//获取tabs的数据
export const getTabsData = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/parameter/setup/getParameterPeriod', { params });
};

//获取delaer的列表数据
export const getVINTableData = (params = {}): Promise<any> => {
    return fetchPost(
        '/claimapi/salesIncentiveClaim/salesIncentiveClaimList',
        params
    );
};

//获取programe的列表数据

export const avaliablePrograms = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/dealer/avaliablePrograms', { params });
};

//导出项目明细

export const exportAvaliablePrograms = (params = {}): Promise<any> => {
    return fetchPost('/claimapi/dealer/exportAvaliablePrograms', params);
};

//获取扩展信息类型
export const extendEdit = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/dealer/extend/edit', { params });
};

//查看已经填写的扩展信息
export const getExtendInfo = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/dealer/extend/view', { params });
};

//保存单个扩展信息
export const saveInfoExtend = (params = {}): Promise<any> => {
    return fetchPostPic('/claimapi/dealer/extend/save', params);
};

//下载扩展信息文档
export const templateDownload = (params = {}): Promise<any> => {
    return fetchPost('/claimapi/dealer/extend/templateDownload', params);
};

//查询上传文档的查询接口，返回到底有什么需要上传的文档
export const supportReview = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/salesIncentiveClaim/review', { params });
};

//上传单个支持文档
export const supportSaveOrUpdateSingle = (params = {}): Promise<any> => {
    return fetchPost('/claimapi/salesIncentiveClaim/saveOrUpdate', params);
};

//批量上传支持文档
// export const supportSaveOrUpdateList = (params = {}): Promise<any> => {
//     return fetchPost('/claimapi/salesIncentiveClaim/uploadBatch', params)
// }
//批量上传支持文档
export const supportSaveOrUpdateList = (
    invoiceConfig = {},
    params = {}
): Promise<any> => {
    return fetchPostPic(
        '/claimapi/salesIncentiveClaim/uploadBatch',
        params,
        invoiceConfig
    );
};

//提交零售记录
export const saveDealerClaimApp = (data = {}): Promise<any> => {
    console.log(data);
    return fetchPost('/claimapi/salesIncentiveClaim/saveDealerClaimApp', data);
};

//延期保存
export const saveDelayDealerClaimApp = (data = {}): Promise<any> => {
    console.log(data);
    return fetchPost(
        '/claimapi/salesIncentiveClaim/saveDealerDelayClaimApp',
        data
    );
};

//查询支持文档样例
export const docSample = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/salesIncentiveClaim/docSample', { params });
};
//删除文档
export const deleteDocSample = (docId: string): Promise<any> => {
    return fetchPost('/claimapi/salesIncentiveClaim/deleteFile', {}, { params: { docId } });
}

//查询支持文档样例2278
export const docSampleDealer = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/claimDocCollection/docSample', { params });
};

//显示支持文档样例
export const docSampleReview = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/salesIncentiveClaim/docSampleReview', {
        params,
    });
};

//显示已经上传的图片 2278
export const docReview = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/claimDocCollection/review', { params });
};
//显示单个上传图片 2276实际是4954再用
export const claimReview = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/dealerClaimSupportingDoc/review', { params });
};

//2275的下一步校验

export const validateReplaceAttributes = (data = {}): Promise<any> => {
    return fetchPost(
        '/claimapi/salesIncentiveClaim/validateReplaceAttributes',
        data
    );
};

// 2278的接口

//获取表格列信息
export const extendTitleList = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/dealer/doc/extendTitleList', { params });
};

//获取表格数据
export const getTablelist = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/dealer/doc/list', { params });
};

//获取新增的 ，扩展字段类型，弹出抽屉
export const getDocView = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/dealer/doc/view', { params });
};

//获取已填写的 ，扩展字段类型，弹出抽屉
export const getDocEdit = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/dealer/doc/edit', { params });
};

//保存抽屉信息
export const dealerSave = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPostPic('/claimapi/dealer/doc/save', params);
};

//删除单个图片
export const deleteFile = (params = { docId: '' }): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchPost(
        '/claimapi/claimDocCollection/deleteFile?docId=' + params.docId
    );
};

//删除一条记录
export const deleteTableItem = (params = {}): Promise<any> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return fetchDelete('/claimapi/dealer/doc/delete', { params });
};

//获取批量上传窗口信息
export const uploadBatchInfo = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/dealer/doc/uploadBatchInfo', { params });
};

//下载扩展信息文档
export const downloadExtendInfoApi = (params: any): void => {
    downloadFile(params, 'application/vnd-excel; char-set=UTF-8').then(
        (res) => {
            console.log(res);
        }
    );
};

//下载证明文件5027
// export const downloadProve = (params: any): void => {
//     downloadFile(params, "application/json; char-set=UTF-8").then(
//         (res) => {
//             console.log(res);
//         }
//     );
// }

export const downloadProve = (params = {}): Promise<any> => {
    return fetchGet(`/claimapi/salesIncentiveClaim/downloadFile`, { params });
};

//批量上传扩展信息2275
export const uploadExtendInfoApi = <T>(
    formData: FormData,
    params: any
): Promise<T> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            data: formData,
            url: '/claimapi/dealer/extend/uploadExtendInfo',
            params: params,
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

//上传文件证明 2277
export const uploadProve = <T>(formData: FormData, params: any): Promise<T> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            data: formData,
            url: '/claimapi/salesIncentiveClaim/region/upload',
            params: params,
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

//获取区域证明文件
export const getProveApi = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/salesIncentiveClaim/region/fileName', {
        params,
    });
};

//在2797跳转时，先给后端存储数据
export const saveClaimDealerProgramInfo = (data = {}): Promise<any> => {
    return fetchPost(
        '/claimapi/ClaimDealerProgramInfoController/saveClaimDealerProgramInfo',
        data
    );
};

//获取user,获取DealerID
export const getUser = (params = {}): Promise<any> => {
    return fetchGet('/usapi/users/currentuser');
};
//获取Dealer相关信息
export const getDealerMessageApi = (dealerID: string): Promise<any> => {
    return fetchGet('/dapi/dealerInfo/' + dealerID);
};
//根据dealerCode和programBU获取区域经理
export const getManagerApi = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/common/regionManagerEnquiry', { params });
};

//5027获取延期申报列表
export const getAvaliableDelayProgramsApi = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/dealer/avaliableDelayPrograms', { params });
};

//申报完成后，给后端保存
export const setClaimDocumentMatching = (data = {}): Promise<any> => {
    return fetchPost(
        '/claimapi/salesIncentiveClaim/claimDocumentMatching',
        data
    );
};

//2273新增 匹配客户关系，异步
export const matchingCustomerTypeApi = (params: any): Promise<any> => {
    return fetchPost('/claimapi/dealer/matchingCustomerType', params);
};

//2278新增 中途上传文件后重新匹配文档
export const rematch = (params: any): Promise<any> => {
    return fetchPost('/claimapi/dealer/doc/rematch', params);
};

export const getTime = (): Promise<any> => {
    return fetchGet('/claimapi/common/getTime');
};

//单个文件OCR验证
export const OCROne = <T>(
    formData: FormData,
    params: any,
    url: string
): Promise<T> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            data: formData,
            url: '/claimapi' + url,
            params: params,
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

//多个文件OCR验证
export const OCRS = <T>(
    formData: FormData,
    param: any,
    url: string
): Promise<T> => {
    // return fetchGet('/claimapi/dealer/doc/save', {params})
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            data: formData,
            url: '/claimapi' + url,
            params: param,
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
